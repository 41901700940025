<script lang="ts">
import type { Schemas } from "#shopware";
export interface HeroSlide {
  id: number;
  title: string;
  description: string;
  linkText: string;
  linkPath: string;
  textColor: string | undefined;
  backgroundColor: string | undefined;
  media: Schemas["Media"];
  footnote: string;
}
</script>

<script setup lang="ts">
const transitionDuration = 350;
const props = defineProps<{
  slides: HeroSlide[];
  delay: number;
}>();
const { slides, delay } = toRefs(props);
const slidesLooped = computed(() =>
  slides.value[0] ? [...slides.value, slides.value[0]] : [],
);
const slideCount = computed(() => slides.value.length);
const currentSlideIndex = ref(0);
const animateTransition = ref(true);
function nextSlide() {
  currentSlideIndex.value++;
  if (currentSlideIndex.value >= slideCount.value)
    setTimeout(resetSlider, transitionDuration);
}
async function resetSlider() {
  animateTransition.value = false;
  await nextTick();
  currentSlideIndex.value = 0;
  // no idea thy nextTick is not enough delay here
  setTimeout(() => (animateTransition.value = true), transitionDuration);
}
const { pause, resume } = useIntervalFn(nextSlide, delay, {
  immediate: false,
});
const animationStyle = computed(() => ({
  left: `-${currentSlideIndex.value * 100}%`,
  transition: animateTransition.value
    ? `left ${transitionDuration}ms ease`
    : "none",
}));

const sliderBackgroundStyle = computed(() => ({
  backgroundColor:
    slidesLooped.value[currentSlideIndex.value]?.backgroundColor || "#0000008c",
  transition: `background-color ${transitionDuration}ms ease`,
}));

const target = ref(null);
const { isOutside } = useMouseInElement(target);
watch(isOutside, (outside) => {
  if (!outside) pause();
  else resume();
});
const wrapper = useTemplateRef("wrapper");
const { width, height } = useElementSize(wrapper);
onMounted(resume);

let touchStartX = 0;
let touchEndX = 0;
const threshold = 30;

function handleTouchStart(event: TouchEvent) {
  if (event.touches && event.touches.length > 0) {
    touchStartX = event.touches[0].clientX;
  }
}

function handleTouchMove(event: TouchEvent) {
  if (event.touches && event.touches.length > 0) {
    touchEndX = event.touches[0].clientX;
  }
}

function handleTouchEnd() {
  const deltaX = touchEndX - touchStartX;

  if (Math.abs(deltaX) > threshold) {
    if (deltaX > 0) {
      currentSlideIndex.value =
        currentSlideIndex.value > 0
          ? currentSlideIndex.value - 1
          : slideCount.value - 1;
    } else {
      nextSlide();
    }
    pause();
  }
}
</script>

<template>
  <div ref="target" class="overflow-hidden relative">
    <div
      ref="target"
      class="overflow-hidden relative"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div
        ref="wrapper"
        class="flex relative left-0 w-full"
        :style="animationStyle"
        @dragstart.prevent
      >
        <NuxtImg
          v-for="slide in slidesLooped"
          :key="slide.id"
          :src="slide.media.url"
          :width="Math.ceil(width)"
          :height="Math.ceil(height) || 600"
          fit="cover"
          class="flex-shrink-0 block object-cover w-full h-full xl:max-h-600px bg-no-repeat"
          @dragstart.prevent
        />
      </div>
    </div>

    <!-- Desktop -->
    <div
      class="hidden lg:flex flex-col items-end md:justify-start absolute top-0 w-full h-full md:w-[500px] lg:w-[550px] xl:w-[600px] pointer-events-auto overflow-hidden backdrop-blur-2 custom-float-right"
      :style="sliderBackgroundStyle"
    >
      <div class="flex w-full h-full relative" :style="animationStyle">
        <div
          v-for="slide in slidesLooped"
          :key="slide.id"
          class="flex-shrink-0 w-full relative p-5 md:p-5"
        >
          <div
            class="text-right max-w-[450px] md:w-[400px] xl:w-[500px] xl:mt-5 mx-5 sm:mr-10"
          >
            <h2
              class="text-7 font-800 line-height-tight py-0 mb-3 xl:mb-5"
              :style="{ color: slide.textColor ?? '#ffffffe6' }"
            >
              {{ slide.title }}
            </h2>
            <p
              class="font-300 text-5.5 2xl:text-6.5 lh-tight"
              :style="{ color: slide.textColor ?? '#ffffffe6' }"
            >
              {{ slide.description }}
            </p>
            <NuxtLink
              :to="slide.linkPath"
              class="text-lg font-bold inline-block mt-5 underline underline-offset-5"
              :style="{ color: slide.textColor ?? '#ffffffe6' }"
            >
              {{ slide.linkText }}
            </NuxtLink>
            <div
              class="c-white/50 text-3 xl:text-3.5 2xl:text-4.5 italic absolute bottom-12 right-10 px-12"
            >
              {{ slide.footnote }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-2 absolute bottom-3 left-6">
        <div
          v-for="(slide, slideIndex) in slides"
          :key="slide.id"
          class="w-[25px] h-[25px] hover:bg-white/50 rd-500 cursor-pointer duration-100"
          :class="[
            currentSlideIndex === slideIndex ||
            (currentSlideIndex === slideCount && slideIndex === 0)
              ? 'bg-white'
              : 'bg-transparent b-2',
          ]"
          @click="[(currentSlideIndex = slideIndex), pause()]"
        ></div>
      </div>
    </div>

    <!-- Responsive Mobile -->
    <div
      class="flex flex-col lg:hidden items-end md:justify-start w-full h-full pointer-events-auto overflow-hidden backdrop-blur-2 custom-float-right bg-scheppach-primary-500!"
      :style="sliderBackgroundStyle"
    >
      <div class="flex w-full h-max relative" :style="animationStyle">
        <div
          v-for="slide in slidesLooped"
          :key="slide.id"
          class="flex-shrink-0 w-full relative p-4 lg:p-10"
        >
          <div class="text-right xl:mt-20">
            <h2
              class="text-6 md:text-8 font-800 line-height-tight p-0 mb-5"
              :style="{ color: slide.textColor ?? '#ffffffe6' }"
            >
              {{ slide.title }}
            </h2>
            <p
              class="font-300 text-4.5 md:text-6 lh-tight"
              :style="{ color: slide.textColor ?? '#ffffffe6' }"
            >
              {{ slide.description }}
            </p>
            <NuxtLink
              :to="slide.linkPath"
              class="text-4 font-bold inline-block mt-5 underline underline-offset-5"
              :style="{ color: slide.textColor ?? '#ffffffe6' }"
            >
              {{ slide.linkText }}
            </NuxtLink>
            <div
              class="c-white/50 italic"
              :class="[slide.footnote ? 'mt-3' : '']"
            >
              {{ slide.footnote }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
